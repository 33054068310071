import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";

import requester from "../../utils/requester";

import styles from "./styles.module.scss";
import { toast } from "react-toastify";

import backArrowLogo from "../../assets/backArrow.svg";

export default function AlbumLinkForm() {
  const { code } = useParams();
  const location = useLocation();
  const [showSecondStep, setShowSecondStep] = useState(false);
  const [album, setAlbum] = useState(null);
  const isAquarium = location.pathname?.includes("grand-aquarium");
  const isMedia = location.pathname?.includes("media");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      code: code,
      phone: undefined,
      email: undefined,
    },
  });

  function onSubmit(data) {
    if (data.code) {
      requester()
        .get(
          isAquarium
            ? "/aquarium/get-ticket-images"
            : isMedia
            ? "/media/get-ticket-images"
            : "/albums/getAlbumImages",
          { params: { code: data.code } }
        )
        .then((res) => {
          const albumData = res?.data?.data;

          if (albumData?.serviceType === "album") {
            setAlbum(albumData);
            setShowSecondStep(true);
          } else if (albumData?.serviceType === "video") {
            window.open(albumData?.videoUrl, "_top");
          } else if (
            albumData?.serviceType === "photo" ||
            albumData.tripType === "grand-aquarium" ||
            albumData.tripType === "media"
          ) {
            window.open(albumData?.albumSharedLink, "_top");
          }
        })
        .catch((err) => {
          toast.info(
            <div style={{ textAlign: "center" }}>
              {err.response?.status === 402
                ? "Hi👋, kindly complete your payment as we agreed with you, thanks"
                : isMedia
                ? "Hi👋, your package will be available soon, thanks"
                : "Hi👋, your package will be available within 24 hours, thanks"}
            </div>
          );
        });
    }
  }

  if (showSecondStep) {
    return (
      <div className={styles.homeCont}>
        <div className={styles.home} style={{ justifyContent: "flex-start" }}>
          <div
            className={styles.backBtn}
            onClick={() => {
              setAlbum(null);
              setShowSecondStep(false);
            }}
          >
            <img src={backArrowLogo} />
            <span style={{ marginTop: 0 }}>Back</span>
          </div>

          <a className="button" href={album?.albumSharedLink} target="_blank">
            Photo
          </a>

          <a className="button" href={album?.videoUrl} target="_blank">
            Video
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.homeCont}>
      <form className={styles.home} onSubmit={handleSubmit(onSubmit)}>
        <div>GET YOUR MOMENTS</div>
        <input
          {...register("code", { required: true })}
          placeholder="Code"
          className={`input ${errors.code && "unvalid"}`}
        ></input>
        <input
          {...register("email", { pattern: { value: /\S+@\S+\.\S+/ } })}
          placeholder="Email"
          className={`input ${errors.email && "unvalid"}`}
        ></input>
        <input
          {...register("phone")}
          placeholder="Phone Number"
          className="input"
        ></input>
        <button className="button" type="submit">
          View Files
        </button>
      </form>
    </div>
  );
}
