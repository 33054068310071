import axios from "axios";
const pendingRequests = new Map();
const MAX_HEADER_SIZE = 16000;

export default () => {
  let token =
    typeof window !== "undefined"
      ? JSON.parse(window.localStorage.getItem("gofimtosoftUserData"))
      : null;

  const instance = axios.create({
    baseURL: "https://fimtosoft.herokuapp.com/",
    // baseURL: "http://localhost:8001",
    // baseURL: "https://fimtosoft-staging-0db374487126.herokuapp.com/",

    headers: {
      "x-version": 11,
      Authorization: `bearer ${token}`,
    },
  });

  instance.interceptors.response.use(
    function (response) {
      if (response.config.requestId)
        pendingRequests.delete(response.config.requestId);
      return response;
    },
    function (error) {
      if (error?.response?.status === 403) {
        window.localStorage.setItem("gofimtosoftUserData", null);
        window.location.href = "/login";
      }
      if (error?.response?.status === 410) {
        const lastVersion = localStorage.getItem("appVersion");
        if (lastVersion != error.response.data.error.error.version) {
          localStorage.setItem(
            "appVersion",
            error.response.data.error.error.version
          );
          window.location.reload();
        }
      }
      if (error.config.requestId)
        pendingRequests.delete(error.config.requestId);
      return Promise.reject(error);
    }
  );

  instance.interceptors.request.use((config) => {
    if (config.requestId) {
      pendingRequests.set(config.requestId, config);
    }

    if (config.headers.Authorization?.length > MAX_HEADER_SIZE) {
      window.localStorage.setItem("gofimtosoftUserData", null);
      window.location.href = "/login";
    }

    return config;
  });

  return instance;
};

export const getRequestStatus = (requestId) => {
  if (pendingRequests.has(requestId)) {
    return pendingRequests.get(requestId);
  }
};
